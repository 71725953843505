@charset "UTF-8";

/*----------------------------------------
accordion
----------------------------------------*/


.accordion {
    --bs-accordion-btn-focus-box-shadow: none;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-down(md) {
        --bs-accordion-btn-icon-width: 16px;
        --bs-accordion-btn-padding-x: 15px;
        --bs-accordion-btn-padding-y: 10px;
        --bs-accordion-body-padding-x: 10px;
        --bs-accordion-body-padding-y: 10px;
        gap: 7.5px 0;
    }
    @include media-breakpoint-up(md) {
        --bs-accordion-btn-icon-width: 30px;
        --bs-accordion-btn-padding-x: 20px;
        --bs-accordion-btn-padding-y: 10px;
        --bs-accordion-body-padding-x: 15px;
        --bs-accordion-body-padding-y: 10px;
        gap: 15px 0;
    }

    .accordion-header {
        position: relative;
    }
    .accordion-button {
        font-family: $font-min;
        font-weight: 500;
        line-height: 1.35;
        span {
            @include media-breakpoint-down(md) {
                padding: 0 25px 0 4px;
            }
            @include media-breakpoint-up(md) {
                padding: 0 18px 0 6px;
            }
        }
        &:before {
            content: "";
            height: 100%;
            position: absolute;
            right: 0;
            @include media-breakpoint-down(md) {
                width: 45px;
            }
            @include media-breakpoint-up(md) {
                width: 68px;
            }
        }

        &:after {
            z-index: 5;
        }
    }

    &-compare {
        @include media-breakpoint-down(md) {
            margin-top: 15px;
        }
        @include media-breakpoint-up(md) {
            margin-top: 30px;
        }
        .accordion-header {
            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                background: #9cc0e9;
                z-index: 5;
                @include media-breakpoint-down(md) {
                    width: 8px;
                }
                @include media-breakpoint-up(md) {
                    width: 12px;
                }
            }
        }
        .accordion-button {
            background: #e0ebf8!important;
            color: #604f30;
            @include media-breakpoint-down(md) {
                @include fs(16px);
            }
            @include media-breakpoint-up(md) {
                @include fs(24px);
            }
            @include media-breakpoint-up(xl) {
                @include fs(32px);
            }
            &:before {
                background: #9cc0e9;
            }
        }

        .accordion-body {
            .read {
                font-weight: 600;
                text-align: center;
                @include media-breakpoint-down(md) {
                    @include fs(16px);
                }
                @include media-breakpoint-up(md) {
                    @include clamp-fs(18px, 30px);
                }
            }

            figure {
                display: flex;
                @include media-breakpoint-down(md) {
                    margin-top: 15px;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
                @include media-breakpoint-up(md) {
                    margin-top: 30px;
                    align-items: flex-start;
                }

                figcaption {
                    color: #604f30;
                    @include media-breakpoint-down(md) {
                        @include fs(16px);
                        margin-top: 15px;
                    }
                    @include media-breakpoint-up(md) {
                        @include clamp-fs(16px, 20px);
                        padding-left: 30px;
                    }
                }
            }
        }
    }

    &-faq {
        .accordion-button {
            background: #ffeed6!important;
            color: #615f53;
            @include media-breakpoint-down(md) {
                @include fs(16px);
            }
            @include media-breakpoint-up(md) {
                @include fs(18px);
            }
            @include media-breakpoint-up(lg) {
                @include fs(24px);
            }
            &:before {
                background: #ffbb5b;
            }
            span {
                display: grid;
                grid-template-columns: auto 1fr;
                &:before {
                    content: "Q．";
                }
            }
        }

        &__popular {
            .accordion-button {
                background: #e5f4ed!important;
                &:before {
                    background: #33aa70;
                }
            }
        }

        .accordion-body {
            p {
                @include media-breakpoint-down(md) {
                    @include fs(16px);
                }
                @include media-breakpoint-up(md) {
                    @include fs(18px);
                }
                @include media-breakpoint-up(lg) {
                    @include fs(24px);
                }
            }
        }
    }
}
