@charset "UTF-8";

/*----------------------------------------
btn
----------------------------------------*/
.btn-wrap {
    display: flex;
    justify-content: center;
}

.btn {
    display: flex;
    transition: all 0.1s ease-in;
    top: 0;
    &:active {
        border: none!important;
    }
    &:hover {
        background: initial;
    }
}

.btn-product {
    background: #ef836b;
    color: #fff;
    box-shadow: 0px 2px 0 #eb6446;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    text-align: center;
    font-family: $font-min;
    font-weight: 700;

    @include media-breakpoint-down(md) {
        @include fs(16px);
        height: 40px;
        width: 180px;
    }
    @include media-breakpoint-up(md) {
        @include fs(24px);
        height: 50px;
        width: 240px;
    }
    @include media-breakpoint-up(xl) {
        @include fs(26px);
        height: 60px;
        width: 290px;
    }

    &:after {
        content: url("../img/icon_arrow06.svg");
        width: 8px;
    }
    &:hover {
        background: #ef836b;
        color: #fff;
    }

    &__table {
        margin: 0 auto;
        @include media-breakpoint-down(md) {
            @include fs(16px);
            height: 38px;
            width: 170px;
        }
        @include media-breakpoint-up(md) {
            @include fs(20px);
            height: 45px;
            width: 240px;
        }
    }
}

.btn-shop-guide {
    background: #71a1d2;
    border: 2px solid #4e89c7;
    color: #fff;
    box-shadow: 0px 3px 0 #4e89c7;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    text-align: center;
    font-family: $font-min;
    font-weight: 700;
    width: 100%;

    @include media-breakpoint-down(md) {
        @include fs(16px);
        height: 40px;
    }
    @include media-breakpoint-up(md) {
        @include fs(24px);
        height: 50px;
    }
    @include media-breakpoint-up(xl) {
        @include fs(26px);
        height: 60px;
    }

    &:after {
        content: url("../img/icon_arrow11.svg");
        width: 16px;
    }
    &:hover {
        background: #71a1d2;
        color: #fff;
    }
}

.btn-faq {
    background: #ffbb5b;
    border: 2px solid #ffaa32;
    color: #fff;
    box-shadow: 0px 3px 0 #ffaa32;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    text-align: center;
    font-family: $font-min;
    font-weight: 700;
    width: 100%;

    @include media-breakpoint-down(md) {
        @include fs(16px);
        height: 40px;
    }
    @include media-breakpoint-up(md) {
        @include fs(24px);
        height: 50px;
    }
    @include media-breakpoint-up(xl) {
        @include fs(26px);
        height: 60px;
    }

    &:hover {
        background: #ffbb5b;
        color: #fff;
    }
}

.btn-top-archive {
    background: #ebbe46;
    color: #fff;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    text-align: center;
    font-weight: 700;
    border-radius: 26px;
    line-height: 1;

    @include media-breakpoint-down(md) {
        @include fs(16px);
        height: 40px;
        width: 180px;
        padding: 0 15px;
    }
    @include media-breakpoint-up(md) {
        @include fs(20px);
        height: 50px;
        width: 260px;
        padding: 0 20px;
    }

    &:after {
        content: url("../img/icon_arrow06.svg");
        width: 12px;
    }
    &:hover {
        background: #ebbe46;
        color: #fff;
    }
}

.btn-top-faq {
    background: #ffbb5b;
    color: #fff;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    text-align: center;
    font-weight: 700;
    border-radius: 26px;
    line-height: 1;

    @include media-breakpoint-down(md) {
        @include fs(16px);
        height: 40px;
        width: 180px;
        padding: 0 15px;
    }
    @include media-breakpoint-up(md) {
        @include fs(20px);
        height: 50px;
        width: 260px;
        padding: 0 20px;
    }

    &:after {
        content: url("../img/icon_arrow06.svg");
        width: 12px;
    }
    &:hover {
        background: #ffbb5b;
        color: #fff;
    }
}

.btn-guide-flow {
    background: #efcb6b;
    box-shadow: 0px 3px 0 #ebbe46;
    color: #fff;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    text-align: center;
    font-weight: 700;
    border-radius: 26px;
    line-height: 1;

    @include media-breakpoint-down(md) {
        @include fs(16px);
        height: 40px;
        width: 80%;
        padding: 0 15px;
    }
    @include media-breakpoint-up(md) {
        @include fs(28px);
        height: 50px;
        width: 80%;
        padding: 0 20px;
    }

    &:after {
        content: url("../img/icon_arrow06.svg");
        width: 12px;
    }
    &:hover {
        background: #efcb6b;
        color: #fff;
    }
}

.btn-post-more {
    color: #604f30;
    border: 1px solid #c5c5c5;
    border-radius: 0;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    text-align: center;
    font-weight: 400;
    line-height: 1;

    @include media-breakpoint-down(md) {
        @include fs(16px);
        height: 37px;
        width: 165px;
        padding: 0 15px;
    }
    @include media-breakpoint-up(md) {
        @include fs(18px);
        height: 37px;
        width: 165px;
        padding: 0 15px;
    }

    &:after {
        content: "+";
        @include media-breakpoint-down(md) {
            @include fs(18px);
        }
        @include media-breakpoint-up(md) {
            @include fs(22px);
        }
    }
    &:hover {
        border: 1px solid #c5c5c5;
        background: #fff;
        color: #604f30;
    }
}

.btn-post-close {
    &:after {
        content: "-";
        margin-bottom: 2px;
    }
}

.btn-back-home {
    color: #604f30;
    border: 1px solid #a0a0a0;
    border-radius: 64px;
    box-shadow: 0px 3px 0 #a0a0a0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 600;
    line-height: 1;

    @include media-breakpoint-down(md) {
        @include fs(16px);
        height: 50px;
        width: 220px;
        padding: 0 15px;
    }
    @include media-breakpoint-up(md) {
        @include fs(20px);
        height: 70px;
        width: 350px;
        padding: 0 15px;
    }

    &:before {
        content: url("../img/icon_home.svg");
        @include media-breakpoint-down(md) {
            width: 20px;
            margin-right: 10px;
        }
        @include media-breakpoint-up(md) {
            width: 35px;
            margin-right: 15px;
        }

    }
    &:hover {
        border: 1px solid #a0a0a0;
        background: #fff;
        color: #604f30;
    }
}

.btn-single {
    color: #fff;
    background: #ebbe46;
    border-radius: 64px;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    text-align: center;
    font-weight: 500;
    line-height: 1;

    @include media-breakpoint-down(md) {
        @include fs(16px);
        height: 35px;
        width: 200px;
        padding: 0 15px;
    }
    @include media-breakpoint-up(md) {
        @include fs(24px);
        height: 50px;
        width: 300px;
        padding: 0 15px;
    }
    &:after {
        content: url("../img/icon_arrow06.svg");
        width: 12px;
    }
    &:hover {
        background: #ebbe46;
        color: #fff;
    }
}
