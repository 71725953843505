@charset "UTF-8";

/*----------------------------------------
table
----------------------------------------*/
table.table {
    &-compare {
        border-color: #615f53;
        th, td {
            white-space: nowrap;
            font-weight: 500;
            color: #604f30;
            text-align: center;
            @include media-breakpoint-down(md) {
                @include fs(16px);
            }
            @include media-breakpoint-up(md) {
                @include fs(20px);
                padding: 15px 15px;
            }
        }

        th {
            vertical-align: middle;
            background: rgba(220,231,244,0.35);
            &.price {
                span {
                    &.top {
                        @include media-breakpoint-down(md) {
                            letter-spacing: 0.2em;
                        }
                        @include media-breakpoint-up(md) {
                            letter-spacing: 0.4em;
                        }
                    }

                    &.middle,
                    &.bottom {
                        @include media-breakpoint-down(md) {
                            @include fs(14px);
                        }
                        @include media-breakpoint-up(md) {
                            @include fs(18px);
                        }
                    }

                    &.bottom {
                        display: inline-block;
                        border: 1px solid #615f53;
                        @include media-breakpoint-down(md) {
                            padding: 0 4px;
                            margin-top: 5px;
                        }
                        @include media-breakpoint-up(md) {
                            padding: 0 7px;
                            margin-top: 5px;
                        }
                    }

                }
            }
        }

        td {
            span {
                display: block;
            }
        }
    }

    &-low {
        border-color: #a0a0a0;

        th, td {
            color: #605f53;
            font-weight: 400;
            @include media-breakpoint-down(md) {
                @include fs(16px);
                padding: 10px 15px;
                display: block;
            }
            @include media-breakpoint-up(md) {
                @include fs(22px);
                padding: 15px 20px;
            }
        }

        th {
            white-space: nowrap;
            text-align: center;
            vertical-align: middle;
            background: rgba(251,242,218,0.5);
            @include media-breakpoint-down(md) {
                border-bottom: 1px solid #a0a0a0;
                padding: 5px 15px;
            }
        }
    }
}
