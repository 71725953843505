@charset "UTF-8";

/*----------------------------------------
badge
----------------------------------------*/
.badge {
    &-new {
        background: #eb6446;
        border-radius: 16px;
        font-weight: 400;
        letter-spacing: 0;
        @include media-breakpoint-down(md) {
            @include fs(12px);
        }
        @include media-breakpoint-up(md) {
            @include fs(12px);
        }
    }

    &-old {
        font-size: 0;
        display: block;
    }
}
