@charset "UTF-8";

/*----------------------------------------
animation
----------------------------------------*/
@keyframes loadingMain {
    0% {
        z-index: 9999;
        visibility: visible;
        opacity: 1;
        display: flex;
    }
    80% {
        z-index: 9999;
        visibility: visible;
        opacity: 1;
    }
    100% {
        z-index: -1;
        visibility: hidden;
        opacity: 0;
        display: none;
    }
}

@keyframes loadingLogo {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
    }
    40% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

@keyframes loadingText {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}
