@charset "UTF-8";

////////////////////////////////////
// Variables
////////////////////////////////////

// color
$black: #2A2C41;
$white: #fff;
$orange: #F16500;
$yellow: #FFE91E;
$gray: #9E9E9E;
$light-blue: #E0F4FF;
$light-gray: #F4F4F4;
$site-main: #008AE6;

// color
$body-color: #605f53;
$link-color: #605f53;


// font
$font-min: "Noto Serif JP", serif;

$grid-gutter-width: 40px;

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    10: 10px,
    15: 15px,
    20: 20px,
    25: 25px,
    30: 30px
  ),
  $spacers
);

// breakpoint
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1240px,
    xxl: 1400px
);

$container-max-widths: (
    sm: 540px,
    md: 100%,
    lg: 100%,
    xl: 1180px
);
$design-sp-width: 828px;


// pager
$pagination-color:                  $black;
$pagination-bg:                     none;
$pagination-border-radius:          50%;
$pagination-border-width:           0;
$pagination-border-color:           none;

$pagination-focus-color:            $black;
$pagination-focus-bg:               none;
$pagination-focus-box-shadow:       none;
$pagination-focus-outline:          0;

$pagination-hover-color:            $site-main;
$pagination-hover-bg:               none;
$pagination-hover-border-color:     none;

$pagination-active-color:           $white;
$pagination-active-bg:              $black;
$pagination-active-border-color:    none;

$pagination-disabled-color:         $black;
$pagination-disabled-bg:            none;
$pagination-disabled-border-color:  none;

$pagination-transition:              color .1s ease-in;

// accordion
//$accordion-button-icon:         url("../img/icon_arrow07.svg");
//$accordion-button-active-icon: url("../img/icon_arrow07.svg");

$accordion-icon-active-color: #fff;
$accordion-icon-color: #fff;

$accordion-button-active-bg: transparent;
$accordion-border-color: none;
$accordion-border-radius: 0;
