@charset "UTF-8";
/*----------------------------------------
page common
----------------------------------------*/
// main 共通
main {

}


main.main-common {
    a.tel {
        @include media-breakpoint-up(lg) {
            pointer-events: none;
        }
    }

    // マージン
    .mt-30 {
        @include media-breakpoint-down(md) {
            margin-top: 25px!important;
        }
        @include media-breakpoint-up(md) {
            margin-top: 30px!important;
        }
    }

    .mb-30 {
        @include media-breakpoint-down(md) {
            margin-bottom: 25px!important;
        }
        @include media-breakpoint-up(md) {
            margin-bottom: 30px!important;
        }
    }

    .mt-50 {
        @include media-breakpoint-down(md) {
            margin-top: 30px!important;
        }
        @include media-breakpoint-up(md) {
            margin-top: 50px!important;
        }
    }

    .mb-50 {
        @include media-breakpoint-down(md) {
            margin-bottom: 30px!important;
        }
        @include media-breakpoint-up(md) {
            margin-bottom: 50px!important;
        }
    }

    .mt-100 {
        @include media-breakpoint-down(md) {
            margin-top: 50px!important;
        }
        @include media-breakpoint-up(md) {
            margin-top: 100px!important;
        }
    }

    .mb-100 {
        @include media-breakpoint-down(md) {
            margin-bottom: 50px!important;
        }
        @include media-breakpoint-up(md) {
            margin-bottom: 100px!important;
        }
    }

    .my-30 {
        @include media-breakpoint-down(md) {
            margin-top: 25px!important;
            margin-bottom: 25px!important;
        }
        @include media-breakpoint-up(md) {
            margin-top: 30px!important;
            margin-bottom: 30px!important;
        }
    }

    .my-50 {
        @include media-breakpoint-down(md) {
            margin-top: 30px!important;
            margin-bottom: 30px!important;
        }
        @include media-breakpoint-up(md) {
            margin-top: 50px!important;
            margin-bottom: 50px!important;
        }
    }
}
