@charset "UTF-8";
/*----------------------------------------
archive
----------------------------------------*/
main.main-archive {
    .page-head {
        .col-12 {
            background-image:
                url("../img/page/archive_head_left.png"),
                url("../img/page/archive_head_right.png");
            background-repeat:
                no-repeat,
                no-repeat;
            @include media-breakpoint-down(md) {
                background-size:
                    20% 40%,
                    20% 40%;
                background-position:
                    left 15px top 35px,
                    right 15px top 35px;
            }
            @include media-breakpoint-up(md) {
                background-position:
                    left 80px top 35px,
                    right 80px top 35px;
            }
        }
    }

    .post-wrap {
        display: flex;
        flex-direction: column;
        &__primary {
            @include media-breakpoint-down(md) {
                gap: 15px 0;
            }
            @include media-breakpoint-up(md) {
                gap: 30px 0;
            }
        }

        &__com {
            @include media-breakpoint-down(md) {
                gap: 20px 0;
                margin-top: 50px;
            }
            @include media-breakpoint-up(md) {
                gap: 50px 0;
                margin-top: 70px;
            }
        }
    }

    .btn-wrap-more {
        @include media-breakpoint-down(md) {
            margin-top: 30px;
        }
        @include media-breakpoint-up(md) {
            margin-top: 50px;
        }
    }

    .btn-wrap-home {
        @include media-breakpoint-down(md) {
            margin-top: 30px;
        }
        @include media-breakpoint-up(md) {
            margin-top: 50px;
        }
    }

    section.media {
        .post-wrap.active {
            @include media-breakpoint-down(md) {
                margin-top: 0px;
            }
            @include media-breakpoint-up(md) {
                margin-top: 0px;
            }
        }
    }

    article {
        .post-head {
            display: flex;
            margin: 0;
            padding: 0;
            line-height: 1;
            background: #f6f5f4;
            @include media-breakpoint-down(md) {
                flex-flow: row wrap;
                justify-content: center;
                padding: 10px 10px;
            }
            @include media-breakpoint-up(md) {
                align-items: center;
                padding: 7px 15px;
            }
            @include media-breakpoint-up(xl) {
                padding: 7px 30px;
            }

            &.post-head-sp {
                @include media-breakpoint-up(md) {
                    display: none;
                }
            }

            &.post-head-pc {
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }

            .badge {
                @include media-breakpoint-down(md) {
                    flex: 0 0 auto;
                }
                &-new {
                    @include media-breakpoint-down(md) {
                        margin-right: 10px;
                    }
                    @include media-breakpoint-up(md) {
                        margin-right: 15px;
                    }
                }
            }

            time {
                font-weight: 400;
                border-radius: 32px;
                @include media-breakpoint-down(md) {
                    flex: 0 0 auto;
                    @include fs(14px);
                }
                @include media-breakpoint-up(md) {
                    @include fs(15px);
                }
            }

            .ttl {
                line-height: 1.35;
                font-weight: 500;
                font-family: $font-min;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                @include media-breakpoint-down(md) {
                    flex: 0 0 100%;
                    @include fs(16px);
                    margin-top: 10px;
                    -webkit-line-clamp: 2;
                }
                @include media-breakpoint-up(md) {
                    margin-left: 15px;
                    @include fs(20px);
                    -webkit-line-clamp: 1;
                }
                @include media-breakpoint-up(lg) {
                    @include fs(25px);
                }
            }


            &__primary {
                background: #fbe0da;
            }

            &__com {
                background: #f6f5f4;
                time {
                    background: #fff;
                    @include media-breakpoint-down(md) {
                        padding: 2px 10px;
                    }
                    @include media-breakpoint-up(md) {
                        padding: 5px 10px;
                    }
                }
            }
        }

        .post-text {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            @include media-breakpoint-down(md) {
                @include fs(15px);
                margin-top: 15px;
                line-height: 1.75;
                letter-spacing: 0.2em;
                -webkit-line-clamp: 3;
            }
            @include media-breakpoint-up(md) {
                @include fs(16px);
                line-height: 1.75;
                letter-spacing: 0.2em;
                margin-top: 15px;
                -webkit-line-clamp: 3;
            }
        }

        &.post-primary {
            @include media-breakpoint-down(md) {
                border: 5px solid #fbe0da;
                padding: 10px;
            }
            @include media-breakpoint-up(md) {
                border: 5px solid #fbe0da;
                padding: 20px;
            }
        }

        &.post-com {
            @include media-breakpoint-down(md) {
                border-bottom: 1px dotted #b3a69d;
                padding: 0px 0px 15px 0px;
            }
            @include media-breakpoint-up(md) {
                border: 1px dotted #b3a69d;
                padding: 20px;
            }

            .post-item {
                display: flex;
                @include media-breakpoint-down(md) {
                    flex-direction: column;
                }
                @include media-breakpoint-up(md) {
                    justify-content: flex-start;
                    flex-flow: row wrap;
                }

                .post-img {
                    @include media-breakpoint-down(md) {
                        width: 100%;
                        text-align: center;
                    }
                    @include media-breakpoint-up(md) {
                        flex: 0 0 150px;
                        height: 113px;
                    }
                    @include media-breakpoint-up(lg) {
                        flex: 0 0 200px;
                        height: 150px;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        aspect-ratio: 4 / 3;
                        object-fit: cover;
                        @include media-breakpoint-down(md) {
                            width: 90%;
                            margin-top: 20px;
                        }
                    }
                }

                .post-body {
                    @include media-breakpoint-up(md) {
                        flex: 0 0 calc(100% - 150px);
                        padding-left: 15px;
                    }

                    @include media-breakpoint-up(lg) {
                        flex: 0 0 calc(100% - 200px);
                        padding-left: 20px;
                    }
                }
            }
        }
    }
}

// single
main.main-single {

    .single-wrap {
        border: 5px solid #fbf2da;
        @include media-breakpoint-down(md) {
            padding: 10px;
        }
        @include media-breakpoint-up(md) {
            padding: 20px;
        }
    }

    .single-head {
        background: #fbf2da;
        display: grid;
        align-items: center;
        @include media-breakpoint-down(md) {
            grid-template-columns: 1fr;
            padding: 10px 10px;
        }
        @include media-breakpoint-up(md) {
            grid-template-columns: auto 1fr;
            padding: 10px 20px;
        }
    }

    .single-text {
        @include media-breakpoint-down(md) {
            margin-top: 15px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 15px;
        }

        p, a, pre, table, div, span, ul, dl, dt, dd  {
            @include media-breakpoint-down(md) {
                @include fs(16px);
            }

            @include media-breakpoint-up(lg) {
                @include fs(18px);
            }
        }
    }

    .head-single {
        margin-bottom: 0;
        @include media-breakpoint-down(md) {
            @include fs(16px);
            margin-top: 10px;
        }
        @include media-breakpoint-up(md) {
            @include fs(22px);
            margin-left: 10px;
        }
        @include media-breakpoint-up(lg) {
            @include fs(26px);
        }
    }

    time {
        line-height: 1;
        @include media-breakpoint-down(md) {
            @include fs(14px);
            text-align: center;
        }
        @include media-breakpoint-up(lg) {
            @include fs(16px);
        }
    }

    .btn-wrap-single {
        display: grid;
        grid-template-columns: auto;
        @include media-breakpoint-down(md) {
            gap: 15px 0;
            margin-top: 30px;
        }

        @include media-breakpoint-up(lg) {
            gap: 30px 0;
            margin-top: 50px;
        }

        .btn {
            margin: 0 auto;
        }
    }
}
