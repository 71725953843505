@charset "UTF-8";
/*----------------------------------------
page
----------------------------------------*/
main.main-common {
    .head-com {
        @include media-breakpoint-down(md) {
            margin: 50px 0 30px 0;
        }
        @include media-breakpoint-up(md) {
            margin: 100px 0 60px 0;
        }
    }

    // faq
    &.page-faq {
        .sec-popular {
            @include media-breakpoint-down(md) {
                margin: 50px 0 0px 0;
            }
            @include media-breakpoint-up(md) {
                margin: 100px 0 0px 0;
            }
        }

        .sec-com {
            @include media-breakpoint-down(md) {
                margin: 50px 0 0px 0;
            }
            @include media-breakpoint-up(md) {
                margin: 80px 0 0px 0;
            }
        }

        .page-foot {
            @include media-breakpoint-down(md) {
                margin-top: 30px;
            }
            @include media-breakpoint-up(md) {
                margin-top: 50px;
            }
            p {
                color: #604f30;
                font-family: $font-min;
                font-weight: 500;
                text-align: center;
                margin: 0;
                padding: 0;
                @include media-breakpoint-down(md) {
                    @include fs(16px);
                }
                @include media-breakpoint-up(md) {
                    @include fs(26px);
                }
                @include media-breakpoint-up(lg) {
                    @include fs(30px);
                    max-width: 800px;
                    margin: 0 auto;
                }
            }
        }
    }

    // guide
    &.page-guide {
        // 共通
        .sec-com {
            position: relative;
            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                background: #fff;
                width: 100%;
                @include media-breakpoint-down(md) {
                    height: 45px;
                }
                @include media-breakpoint-up(md) {
                    height: 90px;
                }
            }
            @include media-breakpoint-down(md) {
                padding: 0px 0 30px 0;
                margin-top: 50px;
            }
            @include media-breakpoint-up(md) {
                padding: 0px 0 75px 0;
                margin-top: 135px;
            }

            p {
                color: #604f30;
                @include media-breakpoint-down(md) {
                    @include fs(16px);
                }
                @include media-breakpoint-up(md) {
                    @include fs(20px);
                }

                &.notice {
                    display: grid;
                    grid-template-columns: auto 1fr;
                    align-items: center;
                    &:before {
                        content: url("../img/icon_notice.svg");
                        @include media-breakpoint-down(md) {
                            width: 35px;
                            margin-right: 15px;
                        }
                        @include media-breakpoint-up(md) {
                            width: 50px;
                            margin-right: 20px;
                        }
                    }
                }

                &.mw-900 {
                    @include media-breakpoint-up(md) {
                        margin-left: auto;
                        margin-right: auto;
                        max-width: 900px;
                    }
                }
            }

            .img-wrap {
                text-align: center;
                margin: 0 auto;
            }

            hr {
                opacity: 1;
                @include media-breakpoint-down(md) {
                    border-width: 2px;
                }
                @include media-breakpoint-up(md) {
                    border-width: 4px;
                }
            }

            .head-guide {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #42210b;
                font-family: $font-min;
                margin: 0 auto;

                @include media-breakpoint-down(md) {
                    width: 310px;
                    height: 45px;
                    @include fs(18px);
                    letter-spacing: 0.2em;
                    margin-bottom: 35px;
                }
                @include media-breakpoint-up(md) {
                    width: 520px;
                    height: 90px;
                    @include fs(36px);
                    letter-spacing: 0.2em;
                    margin-bottom: 75px;
                }

                i {
                    @include media-breakpoint-down(md) {
                        margin-right: 10px;
                    }
                    @include media-breakpoint-up(md) {
                        margin-right: 15px;
                    }
                }

                &:after,
                &:before, {
                    content: "";
                    position: absolute;
                    top: 0;
                    height: 0;
                    width: 0;
                    border: solid;
                    border-color: #fff;
                }

                &:before {
                    @include media-breakpoint-down(md) {
                        left: -25px;
                        border-width: 0px 0px 45px 25px;
                    }
                    @include media-breakpoint-up(md) {
                        left: -50px;
                        border-width: 0px 0px 90px 50px;
                    }
                }

                &:after {
                    @include media-breakpoint-down(md) {
                        right: -25px;
                        border-width: 0px 25px 45px 0px;
                    }
                    @include media-breakpoint-up(md) {
                        right: -50px;
                        border-width: 0px 50px 90px 0px;
                    }
                }
            }

            .head-guide-sub {
                text-align: center;
                color: #fff;
                font-weight: 400;
                margin-bottom: 0;
                @include media-breakpoint-down(md) {
                    @include fs(16px);
                    padding: 7px 15px;
                    margin-bottom: 25px;
                }
                @include media-breakpoint-up(md) {
                    @include fs(24px);
                    padding: 15px 15px;
                    margin-bottom: 30px;
                }
            }

            .banner-item {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                background: #fff;
                border-radius: 8px;
                border: 3px solid #00954c;
                @include media-breakpoint-down(md) {
                    height: 80px;
                }
                @include media-breakpoint-up(md) {
                    height: 200px;
                }

                p {
                    color: #00954c;
                    &.top {
                        font-weight: 600;
                        @include media-breakpoint-down(md) {
                            @include fs(18px);
                        }
                        @include media-breakpoint-up(md) {
                            @include fs(22px);
                        }
                        @include media-breakpoint-up(lg) {
                            @include fs(26px);
                        }
                    }

                    &.bottom {
                        font-weight: 700;
                        @include media-breakpoint-down(md) {
                            @include fs(20px);
                        }
                        @include media-breakpoint-up(md) {
                            @include fs(24px);
                        }
                        @include media-breakpoint-up(lg) {
                            @include fs(32px);
                        }
                    }
                }
            }

            .box {
                display: grid;
                .box-head {
                    color: #fff;
                    text-align: center;
                }

                .box-body {
                    background: #fff;
                    min-width: 100%;
                    @include media-breakpoint-down(md) {
                        @include fs(15px);
                    }
                    @include media-breakpoint-up(md) {
                        @include fs(20px);
                    }
                }

                &-num {
                    @include media-breakpoint-down(md) {
                        grid-template-columns: 30px 1fr;
                    }
                    @include media-breakpoint-up(md) {
                        grid-template-columns: 60px 1fr;
                    }
                    .box-head {
                        font-weight: 600;
                        font-family: $font-min;
                        align-self: center;
                        min-height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @include media-breakpoint-down(md) {
                            @include fs(16px);
                        }
                        @include media-breakpoint-up(md) {
                            @include fs(22px);
                        }
                    }
                    .box-body {
                        @include media-breakpoint-down(md) {
                            padding: 10px 15px;
                        }
                        @include media-breakpoint-up(md) {
                            padding: 10px 15px;
                        }
                    }
                }


                &-notice {
                    grid-template-columns: 1fr;
                    .box-head {
                        border-radius: 16px 16px 0 0;
                        font-weight: 600;
                        font-family: $font-min;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        @include media-breakpoint-down(md) {
                            @include fs(16px);
                            padding: 10px 15px;
                        }
                        @include media-breakpoint-up(md) {
                            @include fs(30px);
                            padding: 15px 30px;
                        }
                        .icon {
                            margin-right: 10px;
                        }
                    }
                    .box-body {
                        border-radius: 0px 0px 16px 16px;
                        @include media-breakpoint-down(md) {
                            padding: 10px 15px;
                        }
                        @include media-breakpoint-up(md) {
                            padding: 20px 30px;
                        }
                    }
                }

                &-img {
                    display: flex;
                    @include media-breakpoint-down(md) {
                        flex-direction: column;
                    }
                    @include media-breakpoint-up(md) {
                        flex-flow: row wrap;
                    }

                    .img-wrap {
                        @include media-breakpoint-up(md) {
                            flex: 0 0 35%;
                        }
                    }

                    p {
                        @include media-breakpoint-down(md) {
                            padding-top: 15px;
                        }
                        @include media-breakpoint-up(md) {
                            flex: 0 0 65%;
                            padding-left: 30px;
                        }
                    }
                }
            }

            // カラーバリエーション
            &.sec-nav {
                background: #e6eef7;
                .head-guide {
                    background: #f5f8fb;
                    &:after,
                    &:before, {
                        border-bottom-color: #f5f8fb;
                    }
                }
            }

            &.sec-flow {
                background: #fcf5e5;
                .head-guide {
                    background: #fdf9ef;
                    &:after,
                    &:before, {
                        border-bottom-color: #fdf9ef;
                    }
                }
                .head-guide-sub { background: #ebbe46; }
                .box {
                    .box-head { background: #ebbe46;}
                }

                hr {
                    border-color: #efcb6b;
                }
            }

            &.sec-regular {
                background: #d9ebe3;
                .head-guide {
                    background: #e7f2ed;
                    &:after,
                    &:before, {
                        border-bottom-color: #e7f2ed;
                    }
                }
                .head-guide-sub { background: #33aa70; }
                .box {
                    .box-head { background: #33aa70;}
                }
                .banner-item {
                    border-color: #00954c;
                    p {
                        color: #00954c;
                    }
                }
            }

            &.sec-pay {
                background: #fdece9;
                .head-guide {
                    background: #fef4f2;
                    &:after,
                    &:before, {
                        border-bottom-color: #fef4f2;
                    }
                }
                .head-guide-sub { background: #ef836b; }
                .box {
                    .box-head { background: #ef836b;}
                }
                .banner-item {
                    border-color: #eb6446;
                    p {
                        color: #eb6446;
                    }
                }
            }

            &.sec-postage {
                background: #ede9e4;
                .head-guide {
                    background: #f3f1ee;
                    &:after,
                    &:before, {
                        border-bottom-color: #f3f1ee;
                    }
                }
                .box {
                    .box-head { background: #cbbaa6;}
                }
            }

            &.sec-swap {
                background: #ede9e4;
                .head-guide {
                    background: #f3f1ee;
                    &:after,
                    &:before, {
                        border-bottom-color: #f3f1ee;
                    }
                }
                .box {
                    .box-head { background: #cbbaa6;}
                }
            }

            &.sec-mypage {
                background: #e5e5de;
                .head-guide {
                    background: #eeeeea;
                    &:after,
                    &:before, {
                        border-bottom-color: #eeeeea;
                    }
                }
            }
        }

        // 個別
        .page-head {
            position: relative;
            @include media-breakpoint-down(lg) {
                height: 500px;
            }
            @include media-breakpoint-down(md) {
                height: 250px;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                z-index: 2;
                @include media-breakpoint-down(lg) {
                    height: 100%;
                    width: calc(100% - 30px);
                    background: url("../img/page/guide_head_sp.png")no-repeat center bottom / contain;
                }
                @include media-breakpoint-up(lg) {
                    height: 400px;
                    width: 100%;
                    background-image:
                        url("../img/page/guide_head_left_pc.png"),
                        url("../img/page/guide_head_right_pc.png");
                    background-repeat:
                        no-repeat,
                        no-repeat;
                    background-position:
                        left 20px top 40px,
                        right 20px top 30px;
                }
                @include media-breakpoint-up(xxl) {
                    max-width: 1420px;
                }
            }
        }

        // 目次
        .sec-nav {
            @include media-breakpoint-down(md) {
                margin-top: 30px;
            }
            @include media-breakpoint-up(md) {
                margin-top: 30px;
            }
            @include media-breakpoint-up(lg) {
                margin-top: 80px;
            }
            .nav-shop-guide {
                margin-top: 0;
            }
        }

        // 購入の流れ
        .sec-flow {
            .img-wrap {
                @include media-breakpoint-down(md) {
                    padding: 0 30px;
                }
                @include media-breakpoint-up(md) {
                    padding: 0 50px;
                }
                @include media-breakpoint-up(lg) {
                    max-width: 750px;
                }
            }

            .flow-wrap1 {
                display: grid;
                grid-template-columns: 1fr;
                @include media-breakpoint-down(md) {
                    gap: 50px 0;
                }
                @include media-breakpoint-up(md) {
                    gap: 70px 0;
                }

                .box {
                    position: relative;
                    &:after {
                        content: url("../img/icon_arrow12.svg");
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        @include media-breakpoint-down(md) {
                            bottom: -35px;
                            @include bg-img-size(75px, 37.5px, 35px);
                        }
                        @include media-breakpoint-up(md) {
                            bottom: -55px;
                            @include bg-img-size(75px, 37.5px, 75px);
                        }
                    }
                    &:last-child:after { display: none; }
                }
            }

            .flow-wrap2 {
                display: grid;
                grid-template-columns: 1fr;
                @include media-breakpoint-down(md) {
                    gap: 20px 0;
                }
                @include media-breakpoint-up(md) {
                    gap: 20px 0;
                }
            }

            .btn-wrap-order {
                @include media-breakpoint-up(lg) {
                    display: none;
                }
            }

            hr {
                @include media-breakpoint-down(md) {
                    margin: 25px 0;
                }
                @include media-breakpoint-up(md) {
                    margin: 75px 0 25px 0;
                }
            }
        }

        // 定期コース
        .sec-regular {
            .regular-wrap1 {
                display: grid;
                @include media-breakpoint-down(md) {
                    grid-template-columns: 1fr;
                    gap: 10px;
                }
                @include media-breakpoint-up(md) {
                    grid-template-columns: 1fr 1fr;
                    gap: 15px 15px;
                }
                @include media-breakpoint-up(xl) {
                    gap: 50px 50px;
                }
            }
        }

        // お支払方法
        .sec-pay {
            .pay-wrap1 {
                display: grid;
                @include media-breakpoint-down(md) {
                    grid-template-columns: 1fr;
                    gap: 10px;
                }
                @include media-breakpoint-up(md) {
                    grid-template-columns: 1fr 1fr 1fr;
                    gap: 15px 15px;
                }
                @include media-breakpoint-up(xl) {
                    gap: 0px 50px;
                }
            }
        }

        // 送料・配送
        .sec-postage {

        }

        // 返品・交換
        .sec-swap {

        }

        // マイページ
        .sec-mypage {

        }
    }
}
