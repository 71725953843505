@charset "UTF-8";
/*----------------------------------------
top
----------------------------------------*/
// loading
.loading {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100vw;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    opacity: 0;

    visibility: visible;
    animation: loadingMain 8s forwards;
    height: 100vh;
    @include media-breakpoint-down(md) {
        display: none!important;
        height: -webkit-fill-available;
    }
    .load-logo {
        opacity: 1;
        animation: loadingLogo 8s;
        @include media-breakpoint-down(md) {
            content: url("../img/top/op_logo_sp.svg");
            width: 118px;
        }
        @include media-breakpoint-up(md) {
            content: url("../img/top/op_logo_pc.svg");
            width: 237px;
        }
    }

    .load-text {
        animation: loadingText 8s;
        @include media-breakpoint-down(md) {
            content: url("../img/top/op_text_sp.svg");
            margin-top: 20px;
            width: 277px;
        }
        @include media-breakpoint-up(md) {
            content: url("../img/top/op_text_pc.svg");
            margin-top: 35px;
            width: 755px;
            padding: 0 30px;
        }
        @include media-breakpoint-up(lg) {
            padding: 0 0px;
        }
    }
}


main.site-top {
    section.guide {
        background: #ede9e4;
        @include media-breakpoint-down(md) {
            padding: 30px 0 0px 0;
        }
        @include media-breakpoint-up(md) {
            padding: 100px 0 0px 0;
        }
    }

    section.read {
        @include media-breakpoint-down(md) {
            padding: 0 0 40px 0;
        }
        @include media-breakpoint-up(md) {
            padding: 80px 0 80px 0;
        }
        .read-common {
            background : linear-gradient(90deg, rgba(255, 255, 165, 1) 0%, rgba(255, 255, 230, 0.46) 37.07%, rgba(255, 255, 255, 0.2) 54.55%, rgba(255, 255, 165, 1) 100%);
            @include media-breakpoint-down(md) {
                padding: 40px 0;
            }
            @include media-breakpoint-up(md) {
                padding: 80px 0;
            }
        }
        .read-sp {
            @include media-breakpoint-up(md) {
                display: none;
            }
            padding-top: 40px;
            img {
                margin-left: 15px;
            }
        }

        .read-bottom {
            @include media-breakpoint-down(md) {
                .container {
                    padding: 0;
                    .row {
                        margin: 0;
                        .col {
                            padding: 0;
                        }
                    }
                }
            }
            @include media-breakpoint-up(md) {
                margin-top: 130px;
                h3 {
                    margin: 0 auto;
                }
            }

        }
    }

    section.reason {
        @include media-breakpoint-down(md) {
            padding: 75px 0;
        }
        @include media-breakpoint-up(md) {
            padding: 150px 0;
        }

        .img-wrap {
            margin-left: auto;
            margin-right: auto;
        }
        .reason01 {
            @include media-breakpoint-down(md) {
                width: 85.5%;
            }
            @include media-breakpoint-up(md) {
                width: 68.8889%;
            }
        }

        .reason02 {
            @include media-breakpoint-down(md) {
                display: none;
            }
            @include media-breakpoint-up(md) {
                width: 91.7543%;
                margin-top: 60px;
            }
        }

        .reason03 {
            @include media-breakpoint-down(md) {
                margin-top: 30px;
            }
            @include media-breakpoint-up(md) {
                margin-top: 60px;
            }
        }

        .reason04,
        .reason05 {
            @include media-breakpoint-down(md) {
                margin-top: 15px;
            }
            @include media-breakpoint-up(md) {
                margin-top: 30px;
            }
        }

        .reason06 {
            @include media-breakpoint-down(md) {
                margin: 60px 0 25px 0;
            }
            @include media-breakpoint-up(md) {
                margin: 150px 0 120px 0;
            }
        }
    }

    section.product {
        background: #fdece9;
        @include media-breakpoint-down(md) {
            padding: 30px 0;
        }
        @include media-breakpoint-up(md) {
            padding: 100px 0 75px 0;
        }
        .head-com {
            img {
                @include media-breakpoint-down(md) {
                    width: 75px;
                    margin: 0 10px 0 5px;
                }
                @include media-breakpoint-up(md) {
                    width: 150px;
                    margin: 0 20px 0 10px;
                }
            }
        }

        .product-wrap {
            display: flex;
            flex-direction: column;
            @include media-breakpoint-down(md) {
                gap: 25px 0;
                margin-top: 25px;
            }
            @include media-breakpoint-up(md) {
                gap: 50px 0;
                margin-top: 50px;
            }

            .product-item {
                border: 1px solid #eb6446;
                background: #fff;
                color: #604f30;
                display: flex;
                flex-flow: row wrap;
                @include media-breakpoint-down(md) {
                    padding: 15px 15px;
                }
                @include media-breakpoint-up(md) {
                    padding: 30px 15px;
                }
                @include media-breakpoint-up(xl) {
                    padding: 50px 30px;
                }

                // rayout
                .head {
                    @include media-breakpoint-down(md) {
                        flex: 0 0 100%;
                        margin-bottom: 15px;
                    }
                    @include media-breakpoint-up(md) {
                        flex: 0 0 100%;
                        margin-bottom: 50px;
                    }
                }

                .img-wrap {
                    display: flex;
                    justify-content: flex-end;
                    align-items: flex-start;
                    @include media-breakpoint-down(md) {
                        flex: 0 0 80px;
                    }
                    @include media-breakpoint-up(md) {
                        flex: 0 0 140px;
                    }
                    @include media-breakpoint-up(lg) {
                        flex: 0 0 180px;
                    }
                    @include media-breakpoint-up(xl) {
                        flex: 0 0 230px;
                    }
                }

                .detail {
                    @include media-breakpoint-down(md) {
                        flex: 0 0 calc(100% - 80px);
                        padding-left: 10px;
                        position: relative;
                    }
                    @include media-breakpoint-up(md) {
                        flex: 0 0 calc(100% - 140px);
                        padding-left: 15px;
                    }
                    @include media-breakpoint-up(lg) {
                        flex: 0 0 calc(100% - 180px);
                        padding-left: 30px;
                        padding-right: 50px;
                    }
                    @include media-breakpoint-up(xl) {
                        flex: 0 0 calc(100% - 230px);
                        padding-left: 50px;
                        padding-right: 80px;
                    }
                }

                .head {
                    background: #fdfaf0;
                    text-align: center;
                    font-family: $font-min;
                    font-weight: 700;
                    @include media-breakpoint-down(md) {
                        @include fs(16px);
                        padding: 5px 0px;
                    }
                    @include media-breakpoint-up(md) {
                        @include fs(22px);
                    }
                    @include media-breakpoint-up(xl) {
                        @include fs(33px);
                    }
                }

                .ttl-wrap {
                    display: flex;
                    @include media-breakpoint-down(md) {
                        flex-direction: column;
                    }
                    @include media-breakpoint-up(md) {
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .ttl {
                        font-family: $font-min;
                        @include media-breakpoint-down(md) {
                            @include fs(18px);
                            order: 2;
                            margin-top: 10px;
                            margin-bottom: 0;
                        }
                        @include media-breakpoint-up(md) {
                            @include fs(28px);
                            order: 1;
                            margin-top: 0px;
                            margin-bottom: 0;
                        }
                        @include media-breakpoint-up(xl) {
                            @include fs(40px);
                        }
                        span {
                            margin-left: 10px;
                            @include media-breakpoint-down(md) {
                                @include fs(14px);
                            }
                            @include media-breakpoint-up(md) {
                                @include fs(16px);
                            }
                            @include media-breakpoint-up(xl) {
                                @include fs(24px);
                            }
                        }
                    }

                    .spec {
                        display: flex;
                        @include media-breakpoint-down(md) {
                            order: 1;
                            gap: 0 5px;
                        }
                        @include media-breakpoint-up(md) {
                            order: 2;
                            gap: 0 8px;
                        }
                        span {
                            display: block;
                            border: 1px solid #a0a0a0;
                            @include media-breakpoint-down(md) {
                                @include fs(12px);
                                padding: 0 3px;
                                letter-spacing: 0;
                            }
                            @include media-breakpoint-up(md) {
                                @include fs(16px);
                                padding: 0 3px;
                            }
                            @include media-breakpoint-up(xl) {
                                @include fs(18px);
                            }

                            &.yellow {
                                background: #f7f19f;
                            }
                        }
                    }
                }

                .component {
                    display: flex;
                    flex-wrap: wrap;
                    @include media-breakpoint-down(md) {
                        gap: 3px 3px;
                        margin: 10px 0 5px 0;
                        @include fs(12px);
                    }
                    @include media-breakpoint-up(md) {
                        @include fs(16px);
                        gap: 0 8px;
                        margin: 15px 0 35px 0;
                    }
                    @include media-breakpoint-up(xl) {
                        @include fs(18px);
                    }
                    span {
                        background: #ebebeb;
                        display: block;
                        @include media-breakpoint-down(md) {
                            @include fs(12px);
                            padding: 0 3px;
                            letter-spacing: 0;
                        }
                        @include media-breakpoint-up(md) {
                            @include fs(16px);
                            padding: 0 3px;
                        }
                        @include media-breakpoint-up(xl) {
                            @include fs(18px);
                        }
                    }
                }

                .body {
                    @include media-breakpoint-down(md) {
                        @include fs(12px);
                    }
                    @include media-breakpoint-up(md) {
                        @include fs(16px);
                    }
                    @include media-breakpoint-up(xl) {
                        @include fs(18px);
                    }
                }

                .btn {
                    @include media-breakpoint-down(md) {
                        margin-top: 15px;
                        margin-left: -30px;
                    }

                    @include media-breakpoint-up(md) {
                        margin-top: 15px;
                    }
                }
            }
        }
    }

    section.compare {
        @include media-breakpoint-down(md) {
            padding: 30px 0;
        }
        @include media-breakpoint-up(md) {
            padding: 100px 0;
        }
        .head-com {
            @include media-breakpoint-down(md) {
                margin-bottom: 20px;
            }
            @include media-breakpoint-up(md) {
                margin-bottom: 80px;
            }
        }
        .scroll-hint-icon {
            padding: 20px 0px 10px 0px;
        }
    }

    section.shop-guide {
        background: rgba(247,229,181,0.35);
        @include media-breakpoint-down(md) {
            padding: 30px 0;
        }
        @include media-breakpoint-up(md) {
            padding: 100px 0;
        }

        .box-guide {
            background: #fff;
            border: 1px solid #ebbe46;
            @include media-breakpoint-down(md) {
                margin-top: 30px;
                padding: 15px 15px;
            }
            @include media-breakpoint-up(md) {
                margin-top: 80px;
                padding: 30px 30px;
            }

            p {
                text-align: center;
                color: #604f30;
                @include media-breakpoint-down(md) {
                    @include fs(16px);
                    margin-top: 15px;
                }
                @include media-breakpoint-up(md) {
                    @include clamp-fs(20px, 28px);
                    margin-top: 50px;
                }
            }

            .img-wrap {
                text-align: center;
                @include media-breakpoint-down(md) {
                    margin-top: 15px;
                }
                @include media-breakpoint-up(md) {
                    margin-top: 80px;
                }
            }
        }
    }

    section.archive {
        @include media-breakpoint-down(md) {
            padding: 30px 0;
        }
        @include media-breakpoint-up(md) {
            padding: 100px 0;
        }

        .post-primary-wrap {
            @include media-breakpoint-down(md) {
                margin: 30px 0 30px 0;
            }
            @include media-breakpoint-up(md) {
                margin: 60px 0 120px 0;
            }
        }

        h3 {
            color: #42210b;
            font-family: $font-min;
            background: #fdf3f0;
            @include media-breakpoint-down(md) {
                @include fs(18px);
                padding: 10px 16px;
                margin-bottom: 10px;
            }
            @include media-breakpoint-up(md) {
                @include fs(30px);
                padding: 10px 30px;
                margin-bottom: 10px;
            }
        }

        .archive-items {
            .archive-item-wrap {
                display: flex;
                flex-direction: column;
                @include media-breakpoint-down(md) {
                    gap: 10px 0;
                }
                @include media-breakpoint-up(md) {
                    gap: 10px 0;
                }

                article {
                    border-bottom: 1px dotted #d9d3ce;
                    padding-bottom: 10px;
                    &:last-child {
                        border-bottom: 1px solid #d9d3ce;
                    }

                    a {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        line-height: 1;

                        @include media-breakpoint-down(md) {
                            flex-flow: row wrap;
                            gap: 5px 0;
                        }
                        @include media-breakpoint-up(md) {
                            flex-flow: row;
                        }

                        span.badge {
                            @include media-breakpoint-down(md) {
                                flex: 0 0 auto;
                                order: 2;
                                margin-left: auto;
                            }
                            @include media-breakpoint-up(md) {
                                flex: 0 0 42px;
                            }
                        }

                        time {
                            text-align: center;
                            @include media-breakpoint-down(md) {
                                flex: 0 0 auto;
                                @include fs(16px);
                                order: 1;
                            }
                            @include media-breakpoint-up(md) {
                                flex: 0 0 130px;
                                @include fs(18px);
                            }
                        }

                        span.text {
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            line-height: 1.35;

                            @include media-breakpoint-down(md) {
                                flex: 0 0 100%;
                                @include fs(16px);
                                order: 3;
                            }
                            @include media-breakpoint-up(md) {
                                flex: 0 0 calc(100% - (42px + 140px));
                                @include fs(20px);
                            }
                            @include media-breakpoint-up(lg) {
                                @include clamp-fs(20px, 24px);
                            }
                        }
                    }
                }
            }
        }

        .archive-media {
            @include media-breakpoint-up(lg) {
                margin-top: 30px;
            }
            @include media-breakpoint-up(xl) {
                margin-top: 100px;
            }
        }

        .btn-top-archive {
            @include media-breakpoint-down(md) {
                margin: 30px auto;
            }
            @include media-breakpoint-up(md) {
                margin: 30px auto;
            }
        }
    }

    section.faq {
        @include media-breakpoint-down(md) {
            padding: 30px 0 0px 0;
        }
        @include media-breakpoint-up(md) {
            padding: 100px 0 0px 0;
        }

        .accordion-faq {
            @include media-breakpoint-down(md) {
                margin-top: 30px;
            }
            @include media-breakpoint-up(md) {
                margin-top: 50px;
            }
        }
        .btn-wrap-faq {
            @include media-breakpoint-down(md) {
                margin-top: 20px;
            }
            @include media-breakpoint-up(md) {
                margin-top: 20px;
            }
            .btn-top-faq {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
}
