@charset "UTF-8";

/*----------------------------------------
typography
----------------------------------------*/
.color-red {
    color: #eb6446!important;
}

.color-yellow {
    color: #ffaa32!important;
}

.head-top-guide {
    text-align: center;
    font-family: $font-min;
    color: #42210b;
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down(md) {
        @include fs(20px);
    }
    @include media-breakpoint-up(md) {
        @include fs(36px);
    }

    div {
        display: flex;
        justify-content: space-between;
        @include media-breakpoint-down(md) {
            width: 175px;
        }
        @include media-breakpoint-up(md) {
            width: 452px;
        }
        &.top {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }

        &.bottom {
            span:nth-child(3) {
                @include media-breakpoint-up(md) {
                    margin-right: 22px;
                }
            }
        }
    }
}

.head-archive {
    background: #fdf3f0;
    color: #42210b;
    text-align: center;
    font-family: $font-min;
    font-weight: 700;
    margin: 0;
    padding: 0;
    line-height: 1;
    @include media-breakpoint-down(md) {
        @include fs(18px);
        letter-spacing: 0.15em;
        padding: 9px 0;
        margin-bottom: 10px;
    }
    @include media-breakpoint-up(md) {
        @include fs(28px);
        letter-spacing: 0.2em;
        padding: 22px 0;
        margin-bottom: 20px;
    }
    @include media-breakpoint-up(lg) {
        @include fs(32px);
    }

    &__news {
        @include media-breakpoint-down(md) {
            margin-top: 10px;
        }
        @include media-breakpoint-up(md) {
            margin-top: 15px;
        }
    }

    &__media {
        @include media-breakpoint-down(md) {
            margin-top: 75px;
        }
        @include media-breakpoint-up(md) {
            margin-top: 150px;
        }
    }
}

.head-faq {
    color: #604f30;
    font-family: $font-min;
    font-weight: 500;
    margin: 0;
    padding: 0;
    line-height: 1;
    @include media-breakpoint-down(md) {
        @include fs(18px);
        margin: 10px 0;
    }
    @include media-breakpoint-up(md) {
        @include fs(24px);
        margin: 10px 0;
    }
    @include media-breakpoint-up(lg) {
        @include fs(28px);
    }
}

p.text-privacy {
    @include media-breakpoint-down(md) {
        @include fs(16px);
    }
    @include media-breakpoint-up(md) {
        @include fs(18px);
    }
    @include media-breakpoint-up(lg) {
        @include fs(22px);
    }
}

// ul
ul {
    &.indent {
        li {
            position: relative;
            padding-left: 1em;
            text-indent: -1em;
            &:before {
                content: "・";
            }
        }
    }
}

// marker
span.marker {
    background:linear-gradient(transparent 80%, #ffff60 20%);
}
