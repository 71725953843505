@charset "UTF-8";

/*----------------------------------------
nav
----------------------------------------*/
// top guide
.nav-guide-wrap {
    @include media-breakpoint-down(md) {
        margin: 25px 0 50px 0;
    }
    @include media-breakpoint-up(md) {
        margin: 50px 0 90px 0;
    }
}
.nav-guide {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;

    .item {
        a {
            display: flex;
            background: #fff;
            border: 2px solid #ebbe46;
            box-shadow: 2px 2px 0 #ebbe46;
            color: #604f30;
            @include media-breakpoint-down(md) {
                @include fs(16px);
            }
            @include media-breakpoint-up(md) {
                @include fs(18px);
            }
            @include media-breakpoint-up(lg) {
                @include fs(20px);
            }
            @include media-breakpoint-up(xl) {
                @include fs(26px);
            }

            span {
                @include media-breakpoint-down(md) {
                    line-height: 1;
                }
                @include media-breakpoint-up(md) {
                    line-height: 1.5;
                }
                @include media-breakpoint-up(lg) {
                    line-height: 1;
                }
            }
        }

        &.item-mypage {
            a {
                border: 2px solid #e4644e;
                box-shadow: 2px 2px 0 #e4644e;
                color: #e4644e;
                span {
                    &:after {
                        content: url("../img/icon_arrow02.svg")!important;
                    }
                }
            }
        }
    }

    &.nav-guide-top {
        @include media-breakpoint-down(md) {
            gap: 15px 0;
        }
        @include media-breakpoint-up(md) {
            gap: 30px 15px;
        }
        @include media-breakpoint-up(lg) {
            gap: 30px 30px;
        }
        .item {
            @include media-breakpoint-down(md) {
                flex: 0 0 100%;
            }
            @include media-breakpoint-up(md) {
                flex: 0 0 calc(33.33333% - 20px);
            }

            a {
                position: relative;
                @include media-breakpoint-down(md) {
                    display: grid;
                    grid-template-columns: 60px auto;
                    align-items: center;
                    height: 50px;
                }
                @include media-breakpoint-up(md) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 185px;
                }

                span {
                    display: flex;
                    @include media-breakpoint-down(md) {
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 0px;
                    }
                    @include media-breakpoint-up(md) {
                        text-align: center;
                        align-items: center;
                        justify-content: center;
                        margin-top: 20px;
                    }

                    &:after {
                        content: url("../img/icon_arrow01.svg");
                        @include media-breakpoint-down(md) {
                            width: 18px;
                            height: 18px;
                            margin-bottom: 2px;
                            margin-right: 15px;
                            align-self: center;
                        }
                        @include media-breakpoint-up(md) {
                            width: 24px;
                            height: 24px;
                            align-self: center;
                            margin-left: 15px;
                        }
                    }
                }
            }
        }
    }

    &.nav-guide-bottom {
        @include media-breakpoint-down(md) {
            gap: 15px 15px;
            margin-top: 15px;
        }
        @include media-breakpoint-up(md) {
            gap: 30px 15px;
            margin-top: 30px;
        }
        @include media-breakpoint-up(lg) {
            gap: 30px 30px;
        }

        .item {
            @include media-breakpoint-down(md) {
                flex: 0 0 calc(50% - 7.5px);
            }
            @include media-breakpoint-up(md) {
                flex: 0 0 calc(33.33333% - 20px);
            }

            a {
                position: relative;
                @include media-breakpoint-down(md) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 70px;
                    padding: 10px 0;
                    letter-spacing: 0;
                }

                @include media-breakpoint-up(md) {
                    display: grid;
                    grid-template-columns: 60px 1fr;
                    align-items: center;
                    justify-content: flex-start;
                    height: 67px;
                    border-radius: 8px;
                    padding: 0;
                }
                @include media-breakpoint-up(lg) {
                    grid-template-columns: 80px 1fr;
                }

                .icon {
                    @include media-breakpoint-up(md) {
                        margin-left: 15px;
                    }
                }

                span {
                    display: flex;
                    @include media-breakpoint-down(md) {
                        text-align: center;
                        align-items: center;
                        justify-content: center;
                        margin-top: 5px;
                    }

                    @include media-breakpoint-up(md) {
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 0px;
                    }

                    &:after {
                        content: url("../img/icon_arrow01.svg");
                        @include media-breakpoint-down(md) {
                            width: 18px;
                            height: 18px;
                            align-self: center;
                            margin-left: 15px;
                        }
                        @include media-breakpoint-up(md) {
                            width: 24px;
                            height: 24px;
                            margin-bottom: 2px;
                            margin-right: 15px;
                            align-self: center;
                        }
                    }
                }
            }
        }
    }

    .icon {
        @include media-breakpoint-down(md) {
            justify-self: center;
        }

        &.icon-gnav01 {
            background: url("../img/icon_gnav01.svg")no-repeat center center / contain;
            @include media-breakpoint-down(md) {
                @include bg-img-size(66.2px, 47.9px, 33px);
            }
            @include media-breakpoint-up(md) {
                @include bg-img-size(66.2px, 47.9px, 66.2px);
            }
        }

        &.icon-gnav02 {
            background: url("../img/icon_gnav02.svg")no-repeat center center / contain;
            @include media-breakpoint-down(md) {
                @include bg-img-size(60.5px, 54.2px, 30px);
            }
            @include media-breakpoint-up(md) {
                @include bg-img-size(60.5px, 54.2px, 60.5px);
            }
        }

        &.icon-gnav03 {
            background: url("../img/icon_gnav03.svg")no-repeat center center / contain;
            @include media-breakpoint-down(md) {
                @include bg-img-size(44.3px, 59.1px, 22px);
            }
            @include media-breakpoint-up(md) {
                @include bg-img-size(44.3px, 59.1px, 44.3px);
            }
        }


        &.icon-gnav04 {
            background: url("../img/icon_gnav04.svg")no-repeat center center / contain;
            @include media-breakpoint-down(md) {
                @include bg-img-size(77.8px, 46.1px, 39px);
            }
            @include media-breakpoint-up(md) {
                @include bg-img-size(77.8px, 46.1px, 77.8px);
            }
        }

        &.icon-gnav05 {
            background: url("../img/icon_gnav05.svg")no-repeat center center / contain;
            @include media-breakpoint-down(md) {
                @include bg-img-size(82.4px, 46.9px, 41px);
            }
            @include media-breakpoint-up(md) {
                @include bg-img-size(82.4px, 46.9px, 82.4px);
            }
        }

        &.icon-gnav06 {
            background: url("../img/icon_gnav06.svg")no-repeat center center / contain;
            @include media-breakpoint-down(md) {
                @include bg-img-size(61.2px, 41.6px, 40px);
            }
            @include media-breakpoint-up(md) {
                @include bg-img-size(61.2px, 41.6px, 31px);
            }
            @include media-breakpoint-up(lg) {
                @include bg-img-size(61.2px, 41.6px, 50px);
            }
        }

        &.icon-gnav07 {
            background: url("../img/icon_gnav07.svg")no-repeat center center / contain;
            @include media-breakpoint-down(md) {
                @include bg-img-size(86.3px, 41.3px, 60px);
            }
            @include media-breakpoint-up(md) {
                @include bg-img-size(86.3px, 41.3px, 43px);
            }
            @include media-breakpoint-up(lg) {
                @include bg-img-size(86.3px, 41.3px, 60px);
            }
        }

        &.icon-mypage {
            background: url("../img/icon_mypage.svg")no-repeat center center / contain;
            @include media-breakpoint-down(md) {
                @include bg-img-size(74.6px, 69.2px, 37px);
            }
            @include media-breakpoint-up(md) {
                @include bg-img-size(74.6px, 69.2px, 34px);
            }
            @include media-breakpoint-up(lg) {
                @include bg-img-size(74.6px, 69.2px, 40px);
            }
        }
    }
}

.nav-contact {
    display: flex;
    @include media-breakpoint-down(md) {
        flex-direction: column;
        gap: 15px 0;
        margin-top: 30px;
    }
    @include media-breakpoint-up(md) {
        justify-content: center;
        gap: 30px 30px;
        margin-top: 30px;
    }

    .nav-link {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0;
        line-height: 1;
        letter-spacing: 0;
        @include media-breakpoint-down(md) {
            padding: 10px 10px;
        }
        @include media-breakpoint-up(md) {
            padding: 10px 10px;
        }

        i {
            margin-right: 10px;
        }

        &:hover {
            opacity: 0.6;
        }

        &.nav-link-tel {
            border-radius: 8px;
            border: 1px solid #00954c;
            color: #00954c;
            font-weight: 900;

            @include media-breakpoint-down(md) {
                @include fs(28px);
            }
            @include media-breakpoint-up(md) {
                @include fs(26px);
            }
            @include media-breakpoint-up(lg) {
                @include fs(26px);
                pointer-events: none;
                &:hover {
                    opacity: 1;
                }
            }
            @include media-breakpoint-up(xl) {
                @include fs(30px);
            }
        }

        &.nav-link-mail {
            border-radius: 8px;
            border: 1px solid #0052b7;
            color: #0052b7;
            font-weight: 700;

            @include media-breakpoint-down(md) {
                @include fs(20px);
            }
            @include media-breakpoint-up(md) {
                @include fs(20px);
            }
            @include media-breakpoint-up(xl) {
                @include fs(22px);
            }
        }

    }
}

.nav-shop-guide,
.nav-page-faq {
    display: grid;
    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
        gap: 15px 0;
        margin-top: 30px;
    }
    @include media-breakpoint-up(md) {
        grid-template-columns: 1fr 1fr;
        gap: 30px 30px;
        margin-top: 80px;
    }
}

.nav-page {
    &.nav-page-faq {
        margin-top: 0;
    }

    &.nav-page-guide {

    }
}
