@charset "UTF-8";

/*----------------------------------------
card
----------------------------------------*/
.card-wrap {
    display: grid;
    width: 100%;
    @include media-breakpoint-down(md) {
        margin-top: 32px;
    }
    @include media-breakpoint-up(md) {
        margin-top: 48px;
    }
}

.card {
    position: relative;
    border: none;
    border-radius: 24px;

    .card-img-top {
        border-radius: 24px 24px 0px 0px;
    }

    .card-header {
        background: transparent;
        border: none;
    }

    .card-footer {
        border: none;
        border-radius: 8px 24px 24px 0px;
        background: transparent;
    }

    .btn {
        @include media-breakpoint-up(md) {
            height: 40px;
            @include fs(14px);
        }
    }

    .card-text {
        @include fs(14px);
        text-align: left;
        line-height: 1.42857;
    }

    .card-title {
        margin: 0;
    }
}

// top
.card-place {

}
