// Mixin
@mixin clearfix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

@mixin fs($size) {
    font-size: $size;
	font-size: math.div($size, 16px) * 1rem;
}

@mixin bg-img-size($width, $height, $widthSp: null) {
    @if $widthSp {
        width: $widthSp;
        height: $widthSp * math.div(strip-unit($height), strip-unit($width));
	} @else {
        width: $width;
        height: $height;
	}
}

@mixin img-sp-vw-size($width) {
    width: sp-vw($width);
}

@function sp-vw($width) {
    @return math.div($width, $design-sp-width) * 100vw;
}

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return math.div($number, ($number * 0 + 1));
    }

    @return $number;
}

// clamp
$min-width-default: 375px; // デフォルトの最小画面幅
$max-width-default: 1920px; // デフォルトの最大画面幅

@function clamp-calc($size-at-min-width, $size-at-max-width, $min-width: $min-width-default, $max-width: $max-width-default) {
    $slope: calc(($size-at-max-width - $size-at-min-width) / ($max-width - $min-width));
    $y-axis-intersection: -1 * $min-width * $slope + $size-at-min-width;
    $y-axis-intersection: round-decimal($y-axis-intersection, 3);
    $slope-vw: calc($slope * 100vw);
    $slope-vw: round-decimal($slope-vw, 3);
    @if $size-at-max-width < $size-at-min-width {
        $temp-max: $size-at-max-width;
        $temp-min: $size-at-min-width;
        $size-at-max-width: $temp-min;
        $size-at-min-width: $temp-max;
    }

    $return-value: clamp(#{$size-at-min-width}, #{$y-axis-intersection} + #{$slope-vw}, #{$size-at-max-width});
    @return $return-value;
}

@function round-decimal($value, $decimal-place) {
    $temp-value: calc($value * pow(10, $decimal-place));
    $temp-value: round($temp-value);
    @return calc($temp-value / pow(10, $decimal-place));
}
@function pow($number, $exponent) {
    $value: 1;

    @if $exponent > 0 {
        @for $i from 1 through $exponent {
            $value: $value * $number;
        }
    }
    @return $value;
}

@mixin clamp-fs($min, $max) {
    font-size: #{clamp-calc($min, $max)};
}

// line-height
@mixin line-height($sp-size, $pc-size: null) {
    @if ($pc-size) {
        @include media-down(sm) {
            line-height: $sp-size;
        }
        @include media-up(md) {
            line-height: $pc-size;
        }
    } @else {
        line-height: $sp-size;
    }
}
